import React, { useContext } from 'react'
import { MobileMenuContext } from './../../contexts/MobileMenuContext'
import { FaBars, FaSearch } from 'react-icons/fa'

import './../../scss/MainNavigation.scss'

function MainNavigation() {
  const { setIsMobileMenuActive } = useContext(MobileMenuContext)

  return (
    <header className='main-header'>
        <ul className='networks-nav'>
            <li className='networks-nav__item'>
                <a href='https://iontelevision.com' className='networks-nav__link networks-nav--ion' title="ION Television">
                    <span className='sr-only'>ION Television logo, go to ION Television home page</span>
                </a>
            </li>
            <li className='networks-nav__item networks-nav__item--ion-mystery'>
                <a href='/' className='networks-nav__link networks-nav--ion-mystery'>
                    <span className='sr-only'>ION Mystery</span>
                </a>
            </li>
            <li className='networks-nav__item networks-nav__item--ion-plus'>
                <a href='https://ionplustv.com' className='networks-nav__link networks-nav--ion-plus' title="ION Plus">
                    <span className='sr-only'>ION Plus Logo, go to ION Plus home page</span>
                </a>
            </li>
        </ul>
        <ul className='mobile-nav'>
          <li className='mobile-nav__item'>
            <a href={`${window.location.protocol}//${window.location.host}`} className="mobile-nav__logo">
              <span className='sr-only'>ION Mystery Logo, go to ION Mystery home page</span>
            </a>
          </li>
          <li className='mobile-nav__item'>
            <button type="button" onClick={() => setIsMobileMenuActive(true)} className="mobile-nav__activate-btn">
              <FaBars /><span className='sr-only'>Open Menu</span>
            </button>
          </li>
        </ul>
        <div className='nav-color-bar'>
            <div className='constrain-content'>
                <a href="/#find-us" className='find-us-link'>
                    <FaSearch /> Find ION Mystery
                </a>
            </div>
        </div>
    </header>
  )
}

export default MainNavigation